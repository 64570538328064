import { translate } from '../svg-transform';
import { ScaleLinear, ScaleTime } from 'd3-scale';

/**
 * Transparent overlay that acts as a hover helper.
 *
 * @param props
 * @returns
 */
export const HoverOverlay = (props: HoverOverlayProps) => {
  const xAxis = props.xScale;
  const yAxis = props.yScale;

  const distanceBetweenXAxisPoints = xAxis(props.ticks[1]) - xAxis(props.ticks[0]);
  const points: [number, number][] = [
    [0, yAxis(0) * 0.02],
    [0, yAxis(0) * 0.95],
    [distanceBetweenXAxisPoints / 2, yAxis(0)],
    [distanceBetweenXAxisPoints, yAxis(0) * 0.95],
    [distanceBetweenXAxisPoints, yAxis(0) * 0.02],
  ];
  return (
    <polygon
      points={points.map((x) => x.join()).join(' ')}
      className="stroke-none hover:stroke-coolGray-300 cursor-pointer fill-transparent"
      transform={translate(xAxis(props.point) - distanceBetweenXAxisPoints / 2, 0)}
      onMouseEnter={() => props.onEnter && props.onEnter(props.point)}
      onMouseLeave={() => props.onLeave && props.onLeave(props.point)}
      onClick={() => props.onSelect && props.onSelect(props.point)}
    ></polygon>
  );
};

export interface HoverOverlayProps {
  yScale: ScaleLinear<number, number>;
  xScale: ScaleTime<number, number>;
  point: Date;
  ticks: Date[];

  onEnter?: { (date: Date): void };
  onLeave?: { (date: Date): void };
  onSelect?: { (date: Date): void };
}
