import classNames from 'classnames';

const defaultColors = ['bg-gold-600', 'bg-navy-200', 'bg-cyan-200'];

export const Legend = (props: LegendProps) => {
  const { alignment } = props;
  return (
    <div className={classNames(alignment === 'vertical' ? 'flex-col' : '', 'flex')}>
      {props.items.map((item, i) => (
        <div className="mr-4 flex items-center text-sm" data-testid={'legend-item-' + item.id} key={item.id}>
          <div className={classNames(item.color || defaultColors[i], 'w-5', 'h-1', 'my-3 mr-2')} />
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export interface LegendProps {
  items: LegendItem[];
  action?: JSX.Element;
  alignment: 'horizontal' | 'vertical';
}

export interface LegendItem {
  id: string;
  color?: string;
  label: string;
}
