import classNames from 'classnames';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { translate } from '../svg-transform';
import { BarSetConfig, DataPoint } from '../types';

/**
 * Displays a time-number domain set of points as bars.
 *
 * @param props
 * @returns
 */
export const BarSet = (props: BarProps) => {
  const xAxis = props.xScale;
  const yAxis = props.yScale;
  const distanceBetweenXAxisPoints = xAxis(props.ticks[1]) - xAxis(props.ticks[0]) * 1.5;

  const barGap = props.barSet.gap || 1.2;

  const barWidth = props?.barWidth || distanceBetweenXAxisPoints / props.barSet.total / barGap;
  const dataPoints = props.points.filter(Boolean).map((result) => result as { x: Date; y: number });

  const barShift =
    props?.barShift ||
    -distanceBetweenXAxisPoints / 2 + (distanceBetweenXAxisPoints * props.barSet.current) / props.barSet.total;

  return (
    <g transform={translate(barShift, 0)}>
      {dataPoints.filter(isInDomain(xAxis)).map((point, i) => {
        const value = yAxis(0) - yAxis(point.y);
        const xPos = xAxis(point.x); // - barWidth * barGap;
        let classes = classNames(props.barClassName, 'animate-shrink', 'cursor-pointer');
        if (point.x.getFullYear() === props.highlightedYear?.getFullYear()) {
          classes = classNames(props.barClassName, 'stroke-coolGray-800', 'animate-shrink', 'cursor-pointer');
        }
        // retrigger animation on domain change
        const keyRange = xAxis
          .domain()
          .map((x) => x.toISOString())
          .join('-');
        return (
          <g
            key={`bar-${props.name}-${keyRange}-${point.x.getFullYear()}-${point.y}`}
            data-testid={`bar-${props.name}`}
          >
            <rect
              x={xPos}
              y={yAxis(0)}
              width={barWidth}
              height={value}
              data-testid={`bar-${props.name}-${point.x.getFullYear()}-${point.y}`}
              transform={`translate(0 -${value})`}
              onMouseEnter={() => props.onEnter && props.onEnter(point.x)}
              onMouseLeave={() => props.onLeave && props.onLeave(point.x)}
              onClick={() => props.onSelect && props.onSelect(point.x)}
              className={classes}
            ></rect>
          </g>
        );
      })}
    </g>
  );
};

const isInDomain = (axis: ScaleTime<number, number>): { (props: { x: Date }): boolean } => {
  return ({ x }) => {
    return axis.domain()[0].getFullYear() <= x.getFullYear() && axis.domain()[1].getFullYear() >= x.getFullYear();
  };
};

export interface BarProps {
  yScale: ScaleLinear<number, number>;
  xScale: ScaleTime<number, number>;
  points: Array<DataPoint>;
  barClassName: string;
  name: string;
  highlightedYear?: Date;
  barSet: BarSetConfig;
  ticks: Date[];
  barWidth?: number;
  barShift?: number;
  onEnter?: { (date: Date): void };
  onLeave?: { (date: Date): void };
  onSelect?: { (date: Date): void };
}
