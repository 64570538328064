import classNames from 'classnames';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { translate } from '../svg-transform';

export const Pointer = (props: PointerProps) => {
  const xAxis = props.xScale;
  const yAxis = props.yScale;
  const barWidth = props?.barWidth || xAxis(props.ticks[1]) - xAxis(props.ticks[0]);
  const fontSize = props?.fontSize || 16;

  const points: [number, number][] = [
    [0, yAxis(0) * 0.02],
    [0, yAxis(0) * 0.95],
    [barWidth / 2, yAxis(0)],
    [barWidth, yAxis(0) * 0.95],
    [barWidth, yAxis(0) * 0.02],
  ];
  const color =
    props.color === 'creme'
      ? classNames('fill-creme stroke-creme')
      : classNames('fill-coolGray-100 stroke-coolGray-300');

  return (
    <g className={classNames('animate-[move_1s_ease-out]')}>
      <polygon
        transform={translate(xAxis(props.value) - barWidth / 2, 0)}
        points={points.map((x) => x.join()).join(' ')}
        className={color}
      />
      <text
        x={xAxis(props.value)}
        textAnchor="middle"
        y={0}
        transform={translate(0, yAxis.range()[1] * 0.85 + fontSize * 1.8)}
        className={classNames('fill-coolGray-800')}
        stroke="none"
        style={{ fontSize: fontSize + 'px' }}
      >
        {props?.specialLabels
          ? props?.specialLabels[props.value.getFullYear()]
          : xAxis.tickFormat(0, '%Y')(props.value)}
      </text>
    </g>
  );
};

export interface PointerProps {
  value: Date;
  yScale: ScaleLinear<number, number>;
  xScale: ScaleTime<number, number>;
  ticks: Date[];
  fontSize?: number;
  specialLabels?: Record<number, string>;
  barWidth?: number;
  color?: 'coolGray' | 'creme';
}
