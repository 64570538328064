/**
 * Formats a number to percent.
 * Default is with 2 decimals in format " 25,51 %"
 */
export const formatPercent = (value: number, decimals = 2) => {
  return value.toLocaleString('DE-de', {
    style: 'percent',
    maximumFractionDigits: value % 2 === 0 ? 0 : decimals,
  });
};
