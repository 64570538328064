import classNames from 'classnames';
import { ScaleLinear } from 'd3-scale';
import { formatCurrency } from '@dtk/formatter';
import { useMediaQuery } from '@dtk/ui-components';

/**
 * A linear number domain y axis with a reference marker instead of axis labels.
 *
 * @param props
 * @returns
 */
export const YAxis = (props: YAxisProps) => {
  const { smallerSm, smallerMd } = useMediaQuery();

  const fontSize = props?.fontSize || 14; //18
  const labelFontSize = props?.labelFontSize || smallerMd ? 20 : 12;
  const referenceFontSize = props?.referenceFontSize || smallerMd ? 20 : 14;
  const yAxis = props.scale;
  const ticks = yAxis.ticks();
  const range = Math.abs(ticks[1] - ticks[0]);

  const checkIfYaxisTextisRendered = (currentTick: number) => {
    if (props.showReference) {
      return Math.abs(currentTick - props.reference) > range * 0.75;
    }
    return true;
  };

  return (
    <g>
      {props.showReference && (
        <g data-testid="y-axis-reference-line" data-value={props.reference}>
          <line
            className="stroke-coolGray-500"
            x1="0"
            y1={yAxis(0)}
            x2="100%"
            y2={yAxis(0)}
            transform={`translate(0 -${Math.max(yAxis(0) - yAxis(props.reference), 0)})`}
            strokeDasharray="4"
          ></line>
          <text
            x="0"
            y={yAxis(0)}
            className={classNames('fill-gray-500')}
            stroke="none"
            transform={`translate(0 -${Math.max(yAxis(0) - yAxis(props.reference) - referenceFontSize * 1.2, 0)})`}
            style={{ fontSize: referenceFontSize + 'px' }}
          >
            {formatCurrency(props.reference, 0)}
          </text>
        </g>
      )}

      {props.label && (
        <g data-testid="y-axis-label">
          <text
            x="0"
            y={yAxis(yAxis.ticks(12)[1]) - labelFontSize * 2}
            className="fill-coolGray-400"
            style={{ fontSize: labelFontSize + 'px' }}
          >
            {props.label}
          </text>
        </g>
      )}

      {!smallerSm &&
        yAxis
          .ticks()
          .filter((_, i) => Boolean(i))
          .map((tick) => (
            <g key={`${tick}-number`}>
              <line
                data-testid="y-axis-line"
                data-value={tick}
                className="stroke-coolGray-400"
                strokeWidth={0.5}
                x1="0"
                y1={yAxis(tick)}
                key={tick}
                x2="100%"
                y2={yAxis(tick)}
                strokeDasharray={props?.dottedLine ? '4' : undefined}
              ></line>
              {checkIfYaxisTextisRendered(tick) && (
                <text
                  key={`${tick}-text`}
                  x="0"
                  y={yAxis(tick)}
                  className={classNames('fill-gray-500')}
                  stroke="none"
                  transform="translate(0,-5)"
                  style={{ fontSize: fontSize + 'px' }}
                >
                  {formatCurrency(tick, 0)}
                </text>
              )}
            </g>
          ))}
    </g>
  );
};

export interface YAxisProps {
  showReference: boolean;
  reference: number;
  label?: string;
  scale: ScaleLinear<number, number>;
  fontSize?: number;
  labelFontSize?: number;
  referenceFontSize?: number;
  dottedLine: boolean;
}
