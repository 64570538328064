import classNames from 'classnames';
import { ScaleLinear, ScaleTime } from 'd3-scale';

/**
 * A (TODO: allow generic domain) time domain x axis rendering a label.
 *
 * @param props
 * @returns
 */
export const XAxis = (props: XAxisProps) => {
  const fontSize = props?.fontSize || 14;
  const xAxis = props.xScale;
  const yAxis = props.yScale;

  return (
    <g transform={`translate(0 ${yAxis.range()[1]})`} data-testid="x-axis">
      <line className="stroke-coolGray-300" x1="0" y1="0" x2="100%" y2="0" strokeWidth="1" strokeDasharray="4"></line>
      {props.ticks.map((x) => (
        <g key={x.toISOString()}>
          <text
            x={xAxis(x)}
            data-testid="x-axis-tick"
            data-value={x.toISOString()}
            textAnchor="middle"
            y={fontSize * 1.8}
            className={classNames(
              'hover:fill-coolGray-800',
              'hover:animate-microwiggle',
              'cursor-pointer',
              props.highlightedYear?.getFullYear() === x.getFullYear()
                ? classNames('animate-microwiggle', 'fill-coolGray-800')
                : 'fill-coolGray-400'
            )}
            stroke="none"
            style={{ fontSize: fontSize + 'px' }}
            onMouseEnter={() => props.onEnter && props.onEnter(x)}
            onMouseLeave={() => props.onLeave && props.onLeave(x)}
            onClick={() => props.onSelect && props.onSelect(x)}
          >
            {props?.specialLabels ? props?.specialLabels[x.getFullYear()] : xAxis.tickFormat(0, '%Y')(x)}
          </text>
        </g>
      ))}
    </g>
  );
};

export interface XAxisProps {
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  ticks: Date[];

  specialLabels?: Record<number, string>;
  fontSize?: number;
  highlightedYear?: Date;
  onEnter?: { (date: Date): void };
  onLeave?: { (date: Date): void };
  onSelect?: { (date: Date): void };
}
