import { IconChevronLeft, IconChevronRight } from '@dtk/ui-components';
import { scaleLinear, scaleTime } from 'd3-scale';
import { useState } from 'react';
import { translate } from '../svg-transform';
import { DataPoint, DataSet, DimensionDescription } from '../types';
import { BarSet } from '../01_element/BarSet';
import { Pointer } from '../01_element/Pointer';
import { XAxis } from '../01_element/XAxis';
import { YAxis } from '../01_element/YAxis';
import { HoverOverlay } from '../01_element/HoverOverlay';

/**
 * A time domain / number domain bar chart with multiple bars, pointers and DTK specific behaviour.
 *
 * TODOs:
 * - Hover behavior on bars can be better
 * - Additional animations
 *
 * @param props
 * @returns
 */
export const BarChart = (props: BarChartProps) => {
  const X_SCALE = 1400;
  const Y_SCALE = 400;
  const xScale = scaleTime()
    .domain(props.xDomain)
    .range([X_SCALE * 0.12, X_SCALE * 0.95]);
  const yScale = scaleLinear()
    .domain(props.yDomain)
    .range([0, Y_SCALE * 0.85]);
  const pointerScale = scaleLinear().domain(props.yDomain).range([0, Y_SCALE]);
  const fontSize = 16;
  const xDimension = props.dimensions.x.datapoints[0];
  const yDimensions = props.dimensions.y.datapoints;
  const colors = props.colors || ['fill-gold-600', 'fill-navy-200', 'fill-cyan-200'];
  const xTicks = Array.from(Array(props.xDomain[1].getFullYear() - props.xDomain[0].getFullYear() + 1).keys()).map(
    (idx) => {
      return new Date(props.xDomain[0].getFullYear() + idx, 0, 1);
    }
  );
  const [barState, setState] = useState({} as BarChartState);
  return (
    <svg className="max-h-full max-w-full select-none" viewBox={`0 0 ${X_SCALE} ${Y_SCALE}`}>
      <YAxis dottedLine={true} scale={yScale} label="Wert Immobilie" reference={props.referenceValue} showReference={true}></YAxis>
      <XAxis
        xScale={xScale}
        yScale={yScale}
        ticks={xTicks}
        highlightedYear={barState.highlight}
        onEnter={(date: Date) => setState({ highlight: date })}
        onLeave={() => setState({ highlight: undefined })}
        onSelect={(date: Date) => props.onDateClick && props.onDateClick(date)}
        fontSize={props.fontSize}
      ></XAxis>
      <Pointer
        value={props.pointer}
        xScale={xScale}
        yScale={pointerScale}
        ticks={xTicks}
        fontSize={props.fontSize}
      ></Pointer>

      {yDimensions.map((yDimension, i) => (
        <BarSet
          barClassName={colors[i]}
          name={yDimension}
          xScale={xScale}
          yScale={yScale}
          key={i}
          highlightedYear={barState.highlight}
          ticks={xTicks}
          barSet={{ current: i, total: yDimensions.length }}
          points={toPoints(props.data, xDimension, yDimension)}
          onEnter={(date: Date) => setState({ highlight: date })}
          onLeave={() => setState({ highlight: undefined })}
          onSelect={(date: Date) => props.onDateClick && props.onDateClick(date)}
        ></BarSet>
      ))}

      {xTicks.map((scale) => {
        return (
          <HoverOverlay
            point={scale}
            ticks={xTicks}
            xScale={xScale}
            yScale={pointerScale}
            key={scale.toISOString()}
            onEnter={(date: Date) => setState({ highlight: date })}
            onLeave={() => setState({ highlight: undefined })}
            onSelect={(date: Date) => props.onDateClick && props.onDateClick(date)}
          ></HoverOverlay>
        );
      })}
      {props.onNavigate && props.navigationLabel && (
        <g
          className="hover:fill-navy-800 hover:stroke-navy-800 cursor-pointer fill-black stroke-black "
          transform={translate(X_SCALE * 0.01, yScale(0) + fontSize)}
          onClick={() => props.onNavigate && props.onNavigate()}
        >
          {props.navigationChevron === 'right' && <IconChevronRight></IconChevronRight>}
          {props.navigationChevron === 'left' && <IconChevronLeft></IconChevronLeft>}
          <text x="15" y={fontSize * 0.8} className="hover:animate-wiggle" stroke="none">
            {props.navigationLabel}
          </text>
        </g>
      )}
    </svg>
  );
};

const toPoints = (data: DataSet, xDimension: string, yDimension: string) => {
  return data.map((point) => ({ x: point[xDimension], y: point[yDimension] } as DataPoint));
};

export interface BarChartProps {
  // we keep it simple for now, extend with other domains as soon as necessary
  xDomain: [Date, Date];
  yDomain: [number, number];
  xUnits: 'years';
  /**
   * The date (only year matters nows) that has been highlighted.
   */
  pointer: Date;
  /**
   * The reference value to highlight in the y axis
   */
  referenceValue: number;
  /**
   * The dimensions from the dataset to display
   */
  dimensions: DimensionDescription;
  /**
   * The data to display.
   */
  data: DataSet;
  /**
   * The colors of the bars, if not using the default.
   */
  colors?: string[];
  fontSize?: number;

  // TODO: Replace with domain pages
  navigationLabel?: string;
  navigationChevron?: 'left' | 'right';
  /**
   * Callback for clicks on navigation
   */
  onNavigate?: { (): void };

  onDateClick?: { (date: Date): void };
}

export interface BarChartState {
  highlight?: Date;
}
